import $ from "jquery"
import { sha256 } from "./sha256"
import { CheckFileExist_StatDir, generate_random_string, get_cookie, delay, send_sign_in_request, download_file } from "./api"
/**
 * this file uses the api.js api
 */

var html_text_template = `
<div class="wrapper" id={id}>
    <div class="location">
        {location}
    </div>
    <div class="horizontal_separator">
    </div>
    <div class="size_mb" id={id}_text>
        {size}
    </div>
    <div class="separator">
    </div>
</div>
`

var authenticated = { auth: false };


function update_paragraph(id, text) {
    let element = document.getElementById(id);
    element.innerHTML = text;
}

function create_a_element(text, href, id, parent_id) {
    const parent = document.getElementById(parent_id);
    const a = document.createElement('a');  // create an 'a' element in the html document
    a.href = href;  // set the href parameter to the relative_location (this is the link to the file)
    a.text = text;  // set the name of the download
    a.id = id;
    parent.appendChild(a);
    parent.append(document.createElement("br"))
    return a;
}

function create_html_template(id, location, size, parent_id) {
    const parent = document.getElementById(parent_id);
    parent.innerHTML += html_text_template.formatUnicorn({ id: id, location: location, size: size });
}


function click_me() {
    $.ajax({
        type: "GET",
        ip: window.location.host, port: window.location.port,
        url: "/redirect/clickme?",
        contentType: "text/plain; charset=utf-8",
        error: function (xhr, status, error) {
            console.log(xhr.getResponseHeader("error"));
            alert("could not connect to the server");
        },
        success: function (data) {
            wait_for_completion("python/clickme.zip");  // add a listener that will check for the file download.
        }
    });
}


function request_past_download_links() {


    $.ajax({
        type: "GET",
        ip: window.location.host, port: window.location.port,
        url: `/request_download_link_from_history?email=${authenticated["email"]}`,
        contentType: "text/plain; charset=utf-8",
        error: function (xhr, status, error) {
            console.log(xhr.getResponseHeader("error"), status, error);
            alert("could not connect to the server");
        },
        success: function (data) {
            $("#incomplete_download_links").html("<h3>not completed downloads</h3>");
            $("#complete_download_links").html("<h3>completed downloads</h3>");

            console.log(data);
            let counter = 0;

            if (data["complete"] != undefined) {
                data["complete"].forEach(function (element) {
                    create_a_element(element, element, `download_link${counter++}`, "complete_download_links");
                });
            }

            if (data["incomplete"] != undefined) {
                data["incomplete"].forEach(function (element) {
                    create_a_element(element, element, `download_link${counter++}`, "incomplete_download_links");
                });
            }
        }
    });

}



function remove_download_urls() {

    if (!authenticated["auth"]) {
        return;
    }

    $.ajax({
        type: "GET",
        ip: window.location.host, port: window.location.port,
        url: `/remove_all_download_links?email=${authenticated["email"]}`,
        contentType: "text/plain; charset=utf-8",
        error: function (xhr, status, error) {
            console.log(xhr.getResponseHeader("error"));
            alert("could not connect to the server");
        },
        success: function (data) {
            request_past_download_links();  // refresh the download history
        }
    });
}


function add_download_link_to_history(url) {

    if (!authenticated["auth"]) {
        return;
    }

    $.ajax({
        type: "GET",
        ip: window.location.host, port: window.location.port,
        url: `/add_download_link_to_history?completed=false&email=${authenticated["email"]}&url=${url}`,
        contentType: "text/plain; charset=utf-8",
        error: function (xhr, status, error) {
            console.log(xhr.getResponseHeader("error"));
            alert("could not connect to the server");
        },
        success: function (data) {
            request_past_download_links();  // refresh the download history
        }
    });
}

function move_download_link_to_completed_history(url) {

    if (!authenticated["auth"]) {
        return;
    }

    $.ajax({
        type: "GET",
        ip: window.location.host, port: window.location.port,
        url: `/move_download_link_to_completed_history?completed=true&email=${authenticated["email"]}&url=${url}`,
        contentType: "text/plain; charset=utf-8",
        error: function (xhr, status, error) {
            console.log(xhr.getResponseHeader("error"));
            alert("could not connect to the server");
        },
        success: function (data) {
            request_past_download_links();  // refresh the download history
        }
    });
    console.log("moving");

}


/**
 * send a download request to the python server
 * event - nullable, passed by the html onClick event
 */
function send_request(event = null) {

    let filename = sha256(generate_random_string(30) + Date.now())  // generate random file name

    if (event != null) {  // if the action was summoned by the onClick event, check if the button was enter.
        if (event.keyCode != 13) {
            return;
        }
    }

    let form = $("#downloader_params");  // get the form data
    let url = $("#url").val();  // get the url
    let resolution = $("#resolution").val();  // get the resolution
    let mode = $("#format_selector").val();  // get the mode

    let location = "python/" + filename + ".zip";

    // this is a little bit of messy codewriting, but in short, i want to check if the file already exists on the
    // server and if it does it want to keep generating filenames untill it is unique, the problem is that if i just
    // do a while loop it will recurse itself no metter what, and i want to avoid it.
    if (CheckFileExist_StatDir(location)["exists"]) {  // to avoid infinite recursion, but again, fucking HOW?
        while (CheckFileExist_StatDir(location)["exists"]) {  // jesus fuckign christ how?
            filename = sha256(generate_random_string(30) + Date.now());
            location = "python/" + filename + ".zip";
        }
        send_request(event);
        return;
    }
    console.log(mode, url, location);

    if (mode == "default") {  // the mode and url are essential for downloading the playlist/video
        alert("please select the correct downloading format");
        return;
    }
    if (url == "") {
        alert("please enter a url");
        return;
    }

    $.ajax({
        type: "GET",
        ip: window.location.hostname, port: window.location.port,
        url: "/redirect/download?",
        data: form.serialize() + `&location=${filename}`,
        contentType: "text/plain; charset=utf-8",
        error: function (xhr, status, error) {
            console.log(xhr.getResponseHeader("error"));
            alert("could not connect to the server");
        },
        success: function (data) {
            // $("#explenation_wrapper").hide();
            // $("#embed_parent_explanation_paragraph").hide();

            wait_for_completion(location);  // add a listener that will check for the file download.

            let cookie_content = get_cookie("download_location");
            if (cookie_content == null) {  // if the cookie is not set, set it
                document.cookie = 'download_location=' + location + ",; SameSite=LAX";  // set a cookie to the location of the file
            } else {  // append the next location to check
                document.cookie = "download_location=" + cookie_content + location + ',; SameSite=LAX';  // set a cookie to the location of the file
            }
            add_download_link_to_history(location);  // add the download link to the history
            embed_process(url, resolution == "" ? "1080" : resolution.replace("p", ""));  // create a little thumbnail of the playlist
            setTimeout(function () {
                alert("thank you for downloading, we will alert you as soon as the files are downloaded!, \nthe id of your file is:" + filename + "\nand the download link can be found at the");
            }, 1000);
        }
    });

    console.log(document.cookie)
}

/**
 * create a playable thumbnail of the video.
 */
function embed_process(url, res) {
    let embed = document.getElementById("embed");  // get the embed element on the page (that we will embed it to)
    let id;
    // do some processing to get the custom url
    if (url.includes("playlist?")) {  // if its a playlist
        id = "videoseries?list=" + url.slice(url.indexOf("?list=") + 6);  // that id is from the word list=
    } else if (url.includes("watch?v=")) { // if its a video
        id = url.slice(url.indexOf("?v=") + 3);  // the id is from the letter v=
    } else {
        return;  // invalid id
    }

    let last_iframe = document.getElementById("embed_video_id");

    if (last_iframe !== undefined) {  // if something is already embeded, we remove it.s
        embed.removeChild(last_iframe);
    }

    const embed_url_final = "https://www.youtube.com/embed/" + id;

    let iframe = document.createElement("iframe")  // create an iframe for the video

    // set the parameters

    iframe.allowFullscreen = true;
    iframe.height = "480";
    iframe.width = "775";
    iframe.src = embed_url_final
    iframe.id = "embed_video_id"

    embed.appendChild(iframe); // add the child to the document
}


/**
 * wait for completion of the download process
 * location - the location that the code will check for existence
 */
async function wait_for_completion(location) {
    await delay(1000);
    if (location == "") { return; }
    let original_location = location;
    let success = true, split_download = false;

    create_html_template(location + "paragraph_element", location, "", "size_paragraphs_wrapper");  // create a paragraph for the size of the file

    let condition = true;
    do {
        var file_check_data = CheckFileExist_StatDir(location);
        console.log(file_check_data["size"], file_check_data["exists"], split_download);
        if (file_check_data["size"] / 1024 >= 2) {
            split_download = true;

            if (file_check_data["size"] / 1024 > 10) {
                alert("sorry, but file size exceeded 10 gb. please select a smaller size configuration.");
                success = false;
            }
        }
        let size = 0;
        try { size = (+(file_check_data["size"])).toFixed(2) } catch (e) { }

        update_paragraph(original_location + "paragraph_element_text", size + " MB");  // update the paragraph with the size of the file
        await delay(2000);
        condition = !split_download ? !file_check_data["exists"] : file_check_data["size"] != 0;
    } while (condition);

    if (success) {
        await delay(3000);  // for some reason straight up downloading fucks up the browser
        // (prob. cause the operating system didn't finish creating the file.)
        console.log("hi")
        if (split_download) {
            let counter = 0;
            location = location.replace(".zip", `-${counter}.zip`);
            console.log(location)
            let file_check_data = CheckFileExist_StatDir(location);
            while (file_check_data["exists"]) {
                console.log(location);
                download_file("../" + location, "your download is complete, please unzip it to view the songs.zip")  // download the created file on the client
                counter += 1;
                location = location.replace(`${counter - 1}.zip`, `${counter}.zip`);
                file_check_data = CheckFileExist_StatDir(location);
            }
        } else {
            download_file("../" + location, "your download is complete, please unzip it to view the songs.zip")  // download the created file on the client
        }
        move_download_link_to_completed_history(location);  // move the download link to the completed history

    }

    document.cookie = document.cookie.replace(location + ",", "").replace(location, "");  // if the cookie is a list, we have to remove ',{location}' but if its a single string we remove {location}
    //parent_urls_for_download.removeChild(document.getElementById(original_location + "a_element"));  // remove the link to the file
    document.getElementById("size_paragraphs_wrapper").removeChild(document.getElementById(original_location + "paragraph_element"));  // remove the paragraph for the size of the file
}


export async function download_file_when_body_loads() {

    $("#format_selector")[0].selectedIndex = 0;

    let download_location = get_cookie("download_location");
    let data = JSON.parse(atob(localStorage.getItem("logged_in_user")));
    send_sign_in_request(data["email"], data["password"], {
        send_feedback: false,
        success_callback: function (status) {
            authenticated = { auth: status, password: data["password"], email: data["email"] }
            request_past_download_links();
        }
    });

    if (download_location != "" && download_location != "/" && download_location != null) {  // if the cookie is set, we check if the file exists
        if (download_location.includes(',')) { // the cookie is a list;
            download_location = download_location.split(',');  // split the cookie into an array

            for (let i = 0; i < download_location.length; i++) {  // for each location in the cookie
                download_location[i] = download_location[i].replace(",", "")
                wait_for_completion(download_location[i])  // download the created file on the client
            }
        } else {
            wait_for_completion(download_location);  // download the created file on the client
        }
    }
}





function on_select(state) {
    console.log("state", state)
    if (state === 2) {
        $('#mp4_selector').show()
    } else {
        $('#mp4_selector').hide()
    }



}



























