import axios from '../config/axiosConfig'
import { useState } from "react";
import styled from "styled-components"

// const Screen = styled.div`
//     width: 100vw;
//     height: 100vh;
// `;
const resolutions = ["2160", "1080", "720", "480", "360", "240", "144"]
const map = {
    "playlist-mp4": 'download_playlist?type=mp4',
    "playlist-mp3": 'download_playlist?type=mp3',
    "video-mp4": 'download_video?type=mp4',
    "video-mp3": 'download_video?type=mp3',
}

/**
 * creates a downloadable <a> element which contains the "data" specified.
 * @param {any} data the data to download
 * @param {String} filename the file's name
 */
export const downloadFromBuffer = (data, filename) => {
    const anchorTag = document.createElement('a');
    anchorTag.href = data;
    anchorTag.download = filename;

    anchorTag.click();
    anchorTag.remove();
}

export default function Downloader() {
    const [disabled, setDisabled] = useState(false);
    const [error, setError] = useState("");
    const [settings, _setSettings] = useState({
        url: "https://www.youtube.com/watch?v=Zk58ht8kFdg&list=PLUQaPeUt0e-rsTkcmNb3TuRwRAQ8muhVP&index=1",
        resolution: "1080",
        type: "playlist-mp4",  // playlist-mp4, playlist-mp3, video-mp4, video-mp3
    });
    const [progress, setProgress] = useState(0);
    const setSettings = (key, value) => {
        settings[key] = value;
        _setSettings(settings => ({
            ...settings,
        }));
    }
    const onChange = (e) => {
        console.log("Awd")
        setSettings("type", e.target.value)
    }

    return <div style={{ width: '100vw', height: '100vh' }}>
        <div style={{ textAlign: 'center', margin: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            <div style={{ width: '100%', margin: 'auto' }}>
                <div>{error}</div>
                <select onChange={onChange}>
                    <option value="playlist-mp4">playlist-mp4</option>
                    <option value="playlist-mp3">playlist-mp3</option>
                    <option value="video-mp4">video-mp4</option>
                    <option value="video-mp3">video-mp3</option>
                </select>
                <input style={{ width: "100%" }} onInput={(e) => { setSettings("url", e.target.value) }} value={settings.url} placeholder="url" />
                {settings.type.includes("mp4") &&
                    <input style={{ width: "100%" }} onInput={(e) => { setSettings("resolution", e.target.value) }} value={settings.resolution} placeholder="resolution" />
                }
            </div>
            <button disabled={disabled || settings.url === "" || !resolutions.includes(settings.resolution.replace("p", "")) || settings.resolution === ""} onClick={() => {
                setError("");
                setDisabled(true);
                alert("your download has started! please wait a bit for the counter to start")
                axios.get(`/download/download_playlist?${map[settings.type]}&url=${encodeURIComponent(settings.url)}&resolution=${settings.resolution}`, {
                    responseType: 'blob',
                    onDownloadProgress: (e) => {
                        setProgress(e.loaded / 1024 / 1024);
                    }
                }).then((res) => {
                    console.log(res);
                    downloadFromBuffer(URL.createObjectURL(res.data), `video.zip`)
                    setDisabled(false)
                }).catch((res) => {
                    setError("error has occurred whilst trying to download. error:", res.data)
                    setDisabled(false)
                })
            }}>download</button>
            <div>progress: {progress.toFixed(3)}MB</div>
        </div>
    </div>
}






/*


*/