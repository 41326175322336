// First we need to import axios.js
import axios from 'axios';
// Next we make an 'instance' of it




let baseURL = process.env.URL || process.env.REACT_APP_PROD_URL_BACKEND;
console.log("aaaaa", window.location.hostname, process.env.REACT_APP_PROD_HOSTNAME)
switch (window.location.hostname) {
    // here goes the logic of determining which port is which
    case "localhost":
        baseURL = `${process.env.REACT_APP_LOCAL_URL}:${process.env.REACT_APP_PORT}/api`;
    default:  // default is already 3200
        break;
}
console.log("baseURL", baseURL)


const instance = axios.create({
    // .. where we make our configurations
    baseURL
    // baseURL: `${API_URL}`
});


// Also add/ configure interceptors && all the other cool stuff


export default instance;