import $ from 'jquery'
/**
 * this is an api Javascript file that contains numerous export functions throughout the code.
 * this document is imported through the html file in that calls other javascript files (unless it is imported directly through node).
 * in each file this api is being used there will be a little comment at the top of the file.
 * enjoy ;)
 */
String.prototype.formatUnicorn = String.prototype.formatUnicorn || function () {

    "use strict";
    var str = this.toString();
    if (arguments.length) {
        var t = typeof arguments[0];
        var key;
        var args = ("string" === t || "number" === t) ?
            Array.prototype.slice.call(arguments)
            : arguments[0];

        for (key in args) {
            str = str.replace(new RegExp("\\{" + key + "\\}", "gi"), args[key]);
        }
    }

    return str;
};

export function play_sound(path, loop = false) {
    let audio = new Audio(path);
    audio.loop = loop;
    audio.play();
}


export function remove_cookies(cookie_name) {
    document.cookie = cookie_name + "="
}


/**
 * removes a cookie from the client's browser using jquery
 * @param {String} string
 * @param {String} char
 * @returns
 */
export function find_all_indexes_of_char(string, char) {
    let indexes = [];

    for (let i = 0; i < string.length; i++) {
        if (string[i] == char) {
            indexes.push(i);
        }
    }
    return indexes.length == 0 ? -1 : indexes;
}


/**
 * sets the element's state as hidden (true) / shown (false)
 *
 * @param {string} id
 * @param {boolean} state
 */
export function toggle_animation_element(id, state) {
    document.getElementById(id).style.hidden = state;
}


/**
 * wait X milliseconds
 * use await delay(X)
 */
export function delay(milliseconds) {
    return new Promise(resolve => {
        setTimeout(resolve, milliseconds);
    });
}


/**
 * XORs two strings together and returns the result
 *
 * @param {String} string_one
 * @param {String} key
 * @returns string containing the result or the XOR operation
 */
export function XOR_two_strings(string_one, key) {

    let result = [];

    for (let i = 0; i < string_one.length; i++) {
        result.push(String.fromCharCode(string_one.charCodeAt(i) ^ key.charCodeAt(i)));
    }

    return result.join("");
}

/**
 * returns a random number between min and max
 * (where min <= n <= max)
 * @param {*} min
 * @param {*} max
 * @returns Integer (as specified above).
 */
export function random(min, max) {
    // returns a random number between min and max (where min <= n <= max)
    return Math.floor(Math.random() * (max - min + 1)) + min;
}


/**
 * download the inputed file to the client's browser
 * @param {relative_location} - the relative path (from the server) to the file.
 * name - the display name of the (does not have anything to do with the relative_location attrib.)
 */
export function download_file(relative_location, name) {
    const a = document.createElement('a')  // create an 'a' element in the html document
    a.href = relative_location  // set the href parameter to the relative_location (this is the link to the file)
    a.download = name  // set the name of the download
    document.body.appendChild(a)  // add the element to the html body
    a.click() // simulate a click
    document.body.removeChild(a)  // delete the child
}


/**
 * check if file is present on the server
 * server file to check - a server file defined by a relative path, no need for a ./ or a / at the beginning
 * the data returned is a dictionary containing the following elements:
 * ["exists"] - bool - if the file exists
 * ["size"] - the size of the parent directory
*/
export function CheckFileExist_StatDir(serverFileToCheck) {

    let fileToCheck = window.location.origin + "/" + serverFileToCheck + "?exists=check"
    try {
        var http = new XMLHttpRequest();
        http.open('GET', fileToCheck, false);  // send a request to the server to check the file
        http.send();
    } catch (err) {
        console.log("error checking size of file:", err);
    }
    const data = JSON.parse(http.responseText);
    return data;  // check the server response, by internet standards, if the response is 200 that means the file exists, any
}


export function is_valid_phone_number(phone_number) {

    if (/\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/) {
        return phone_number;
    }
    return null;
}


/**
 * check with regex if the email has a valid structure
 * NOTE: this does not check if the email's domain / username exists, just if the email has the correct structure
 * @param {string} mail
 * @returns the mail (without white spaces) if is valid, else null
 */
export function is_valid_email(mail) {
    mail = mail.replaceAll(" ", "");

    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mail)) {
        return mail;
    }
    return null;
}


/**
 * gets server cookie by name
 * @param {*} name
 * @returns
 */
export function get_cookie(name) {
    // Cookies seperated by ; Key->value seperated by =
    if (document.cookie.length <= 0) { return null; }
    let cookies = document.cookie.split("; ")
    for (var i = 0; i < cookies.length; i++) {
        const pair = (String)(cookies[i]).split("=")
        if (pair[0] == name)
            return pair[1];
    }
    // A cookie with the requested name does not exist
    return null;
}


export function remove_cookie(cookie_name) {
    document.cookie = document.cookie.replace(cookie_name + "=" + get_cookie(cookie_name), "");

}


export function replace_cookie_text(cookie_name, new_data) {
    document.cookie = document.cookie.replace(cookie_name + "=" + get_cookie(cookie_name), cookie_name + "=" + new_data);
}


/**
 * makes id of random characters with the desired length
 * @param {*} length
 * @returns
 */
export function generate_random_string(length) {
    var result = ''  // output result
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!@#$%^().';  // list of available characters that we can use in a filename

    for (let i = 0; i < length; i++) {  // run with the length that was requested
        result += characters.charAt(Math.floor(Math.random() * characters.length));  // generate a random index between 0 and the length and use the character at that position
    }

    return result;  // return the result
}


export function open_side_panel(panel_id, width) {
    document.getElementById(panel_id).style.width = width;
}


export function close_side_panel(panel_id) {
    document.getElementById(panel_id).style.width = "0";
}


/**
 *
 * @param {*} string
 * @param {*} roof if set, integers above the roof will be removed
 */
export function get_all_integers_in_a_string(string, roof = null) {

    let integers = [];
    for (let i = 0; i < string.length; i++) {
        if (Number.isInteger(string[i])) {
            if (roof == null || string[i] <= roof) {
                integers.push(parseInt(string[i]));
            }
        }
    }
    return integers

}


/**
 * encrypts a password using a method i came up with, so probably not that good
 * PS. if you want to send this password in a url use sha256() on it to remove the pesky special unicode characters
 */
export function encrypt_password(password, email) {

    let key = email.slice(0, password.length);  // get the key from the email
    let new_password = sha256(XOR_two_strings(password, key));  // XOR the password with the key

    let indexes = get_all_integers_in_a_string(new_password, email.length - 1);  // get all the integers in the sha256 hash
    let email_letters = "", length_difference = password.length - indexes.length;

    for (let i = 0; i < length_difference; i++) {  // we want to make the number of integers be equal the old password's length
        indexes.push(0);
    }
    for (let i = 0; i < password.length; i++) {  // take the letters from the email at the number's index and place it in a new key
        email_letters += email[indexes[i]];
    }

    let new_password_xor_key = sha256(XOR_two_strings(password, email_letters));  // XOR with the password and the new key
    return XOR_two_strings(new_password_xor_key, new_password);  // XOR that with the new password to get the absolute new password
}


export function encrypt_password_w_key(password, email) {

    let sha256_password = sha256(encrypt_password(password, email));
    let private_key = generate_random_string(sha256_password.length);

    let new_password = btoa(XOR_two_strings(sha256_password, private_key));


    return [new_password, private_key, sha256_password];
}

export function decrypt_base64_password_w_key(password, key) {
    let base64_password = atob(password.replace("-", "+"));

    return XOR_two_strings(base64_password, key);
}




export function send_sign_in_request(email, registration_password, { additional_url_info = {}, send_feedback = true, last_iteration = false, success_callback = null } = {}) {
    //stop form submission
    $("#sign_in_button").attr('disabled', 'disabled');
    let [password, key] = encrypt_password_w_key(registration_password, email);

    send_encrypted_message(
        "/redirect/register_user_main?",
        Object.assign({
            email: email,
            password: password,
        }, additional_url_info),

        key,
        function (data, textStatus, request) {
            let response_value = request.getResponseHeader("response_value");

            if (response_value == "correct_password") {
                //logged_in(password, email);
                if (send_feedback) { alert("you have been logged in") }
                localStorage.setItem("logged_in_user", btoa(JSON.stringify({ email: email, password: registration_password })));
                console.log(atob(localStorage.getItem("logged_in_user")));
            } else if (response_value == "wrong_password") {
                if (send_feedback) { alert("the password or email is incorrect"); }
            } else {
                if (send_feedback) { alert("the user does not exist"); }
            }
            if (success_callback) {
                success_callback(response_value == "correct_password" ? true : false);
            }
            $("#sign_in_button").removeAttr('disabled');
        },
        {
            error_callback: function (JqueryXMLHttpRequest, textStatus, errorThrown) {
                alert("error sending data to server. \nerror: " + JqueryXMLHttpRequest.getResponseHeader("error"));
            }
        }
    );
}














// for some unknown reason imports break my html so dumbessness here i goooooooooo

/**
 * a sha256 algorithm that i yoinked from someone on the internet
 * thank you: https://geraintluff.github.io/sha256/
 *
 * @param {String} string - a string to encode
 * @returns {String} sha256 hash
 */

export function sha256(string) {
    function rightRotate(value, amount) {
        return (value >>> amount) | (value << (32 - amount));
    };

    var mathPow = Math.pow;
    var maxWord = mathPow(2, 32);
    var lengthProperty = 'length'
    var i, j; // Used as a counter across the whole file
    var result = ''

    var words = [];
    var asciiBitLength = string[lengthProperty] * 8;

    //* caching results is optional - remove/add slash from front of this line to toggle
    // Initial hash value: first 32 bits of the fractional parts of the square roots of the first 8 primes
    // (we actually calculate the first 64, but extra values are just ignored)
    var hash = sha256.h = sha256.h || [];
    // Round constants: first 32 bits of the fractional parts of the cube roots of the first 64 primes
    var k = sha256.k = sha256.k || [];
    var primeCounter = k[lengthProperty];

    var hash = [], k = [];
    var primeCounter = 0;

    var isComposite = {};
    for (var candidate = 2; primeCounter < 64; candidate++) {
        if (!isComposite[candidate]) {
            for (i = 0; i < 313; i += candidate) {
                isComposite[i] = candidate;
            }
            hash[primeCounter] = (mathPow(candidate, .5) * maxWord) | 0;
            k[primeCounter++] = (mathPow(candidate, 1 / 3) * maxWord) | 0;
        }
    }

    string += '\x80' // Append Ƈ' bit (plus zero padding)
    while (string[lengthProperty] % 64 - 56) string += '\x00' // More zero padding
    for (i = 0; i < string[lengthProperty]; i++) {
        j = string.charCodeAt(i);
        if (j >> 8) return; // ASCII check: only accept characters in range 0-255
        words[i >> 2] |= j << ((3 - i) % 4) * 8;
    }
    words[words[lengthProperty]] = ((asciiBitLength / maxWord) | 0);
    words[words[lengthProperty]] = (asciiBitLength)

    // process each chunk
    for (j = 0; j < words[lengthProperty];) {
        var w = words.slice(j, j += 16); // The message is expanded into 64 words as part of the iteration
        var oldHash = hash;
        // This is now the undefined working hash", often labelled as variables a...g
        // (we have to truncate as well, otherwise extra entries at the end accumulate
        hash = hash.slice(0, 8);

        for (i = 0; i < 64; i++) {
            var i2 = i + j;
            // Expand the message into 64 words
            // Used below if
            var w15 = w[i - 15], w2 = w[i - 2];

            // Iterate
            var a = hash[0], e = hash[4];
            var temp1 = hash[7]
                + (rightRotate(e, 6) ^ rightRotate(e, 11) ^ rightRotate(e, 25)) // S1
                + ((e & hash[5]) ^ ((~e) & hash[6])) // ch
                + k[i]
                // Expand the message schedule if needed
                + (w[i] = (i < 16) ? w[i] : (
                    w[i - 16]
                    + (rightRotate(w15, 7) ^ rightRotate(w15, 18) ^ (w15 >>> 3)) // s0
                    + w[i - 7]
                    + (rightRotate(w2, 17) ^ rightRotate(w2, 19) ^ (w2 >>> 10)) // s1
                ) | 0
                );
            // This is only used once, so *could* be moved below, but it only saves 4 bytes and makes things unreadble
            var temp2 = (rightRotate(a, 2) ^ rightRotate(a, 13) ^ rightRotate(a, 22)) // S0
                + ((a & hash[1]) ^ (a & hash[2]) ^ (hash[1] & hash[2])); // maj

            hash = [(temp1 + temp2) | 0].concat(hash); // We don't bother trimming off the extra ones, they're harmless as long as we're truncating when we do the slice()
            hash[4] = (hash[4] + temp1) | 0;
        }

        for (i = 0; i < 8; i++) {
            hash[i] = (hash[i] + oldHash[i]) | 0;
        }
    }

    for (i = 0; i < 8; i++) {
        for (j = 3; j + 1; j--) {
            var b = (hash[i] >> (j * 8)) & 255;
            result += ((b < 16) ? 0 : '') + b.toString(16);
        }
    }
    return result;
};

export function remove_char_at_index(string, index) {
    return string.substring(0, index) + string.substring(index + 1);
}


/**
 *
 */
export function activate_login_window(login_container, blur_container) {
    $(`#${blur_container}`).addClass("blur");
    $(`#${login_container}`).removeAttr('hidden');
}

export function json_to_url_query(json_data) {
    let final_string = "";
    for (let key in json_data) {
        final_string += `${key}=${json_data[key]}&`;
    }
    return remove_char_at_index(final_string, final_string.length - 1);
}


/**
 *
 *
 * @param {String} url - the url to get the data from
 * @param {Dictionary} url_arguments - the arguments to send to the url (should be in the form of {key: value}, element: password:value is required!)
 * @param {String} key - the key to use to decrypt / encrypt the data
 * @param {Function} success_callback
 * @param {String} headers - (optional) the headers to send to the url
 * @param {String} host_name - (optional) the host name to send to the url (if null will take the hostname of the current url)
 * @param {String} port - (optional) the port to send to the url (if null will take the port of the current url)
 * @param {Function} error_callback - (optional) the error callback
 * @param {String} request_type - (optional) the request type (GET, POST, etc..) default is GET
 * @returns
 */
export function send_encrypted_message(url, url_arguments, key, success_callback, { headers = {}, host_name = null, port = null, error_callback = null, request_type = "GET" }) {

    if (port === null) { port = window.location.port; }
    if (host_name === null) { host_name = window.location.hostname; }
    let url_arguments_str = new URLSearchParams(url_arguments).toString();

    // create a request and add it to global variables
    console.log(url_arguments_str);
    const request = $.ajax({
        type: request_type,
        ip: host_name, port: port,
        url: url,
        data: `direct=1&${url_arguments_str}`,
        headers: Object.assign({ "response_value": "1" }, headers),
        contentType: "text/json; charset=utf-8",
        success: function (data, textStatus, request) {  // the status will (mostly) always be 200, because we are resending it from the server.
            let password = request.getResponseHeader("response_value");
            console.log("res", password);
            password = btoa(decrypt_base64_password_w_key(password, key));;
            url_arguments["password"] = password;
            url_arguments_str = new URLSearchParams(url_arguments).toString();
            $.ajax({
                type: request_type,
                ip: host_name, port: port,
                url: url,
                data: `direct=XX&${url_arguments_str}`,
                headers: Object.assign({ "response_value": "1" }, headers),
                contentType: "text/plain; charset=utf-8",
                success: success_callback
            });

        },
        error: function (JqueryXMLHttpRequest, textStatus, errorThrown) {
            if (error_callback !== null) { error_callback(JqueryXMLHttpRequest, textStatus, errorThrown); }
        }

    });

    return request;
}
