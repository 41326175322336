import '../css/loading_animation_moving_letters.css';
import '../css/api.css';
import '../css/default/index.css';

import { Link } from "react-router-dom";
import { close_side_panel, open_side_panel } from '../js/api'
import { enable_login_popup, disable_login_popup, process_sign_in, process_sign_up, switch_to_sign_in, process_shut_down, switch_to_sign_up } from '../js/index';
import $ from 'jquery'

/*
    this page isn't as pretty because its a heavily modified version of my legacy vanilla html site.
 */
export default function MainPage() {

    return <>
        <div id="site_panel_container">
            <div id="site_navigation_panel" className="side_panel">
                <a href="javascript:void(0)" className="close_button" onClick={() => close_side_panel('site_navigation_panel')}>×</a>
                <Link to="/downloader">DL</Link>
                <Link to="/doom">DOOM</Link>
                <Link to="/course_projects">Course Projects</Link>
                <Link to="/downloader">Youtube video downloader</Link>
                <Link to="/hangman">Hangman</Link>
                <Link to="/autoplay">autoplay test</Link>
                <Link to="/animations">Animations</Link>
                <Link to="javascript:void(0)" onClick={() => {
                    $('#login_popup').show();
                    $('#sign_in_form').hide();
                    $('#shut_down_form').show()
                }}>Shut Down</Link>
            </div>
            <img id="open_sidepanel_button" src="./images/show.png" onClick={() => open_side_panel('site_navigation_panel', '350px')} className="open_sidepanel_button" />
        </div>

        <div id="main_body_container">

            <h1 id="title">
                Welcome!
            </h1>

            <div id="explanation_text">
                <p>
                    this, this page, is my very first page in html. <br />
                    for that, i don't expect it to be anything but garbage but, it has some potential <br />
                    <br />
                    quick rundown of what the heck is going on in this site: <br />
                    the current page is the lending page, next you have a couple of options <br /><br />
                    <u><b>Hangman</b></u> - im pretty sure it's self explanatory <br /><br />
                    <u><b>YoutubeDownloader</b></u> - this is an integration of a python script that utilizes yt_dlp
                    in order to download videos from youtube. because im a lazy fuck (and don't have a public domain) this uses a local python server that intercepts requests.

                </p>
            </div>
        </div>

        <img id="account_profile_picture" src="./images/not_logged_in.png" alt="" className="not_logged_in_image" onClick={enable_login_popup} />

        <div className="popup" id="login_popup" hidden>
            <div className="popup_content absolute_center_of_parent">
                <a href="javascript:void(0)" className="login_close_button" onClick={() => disable_login_popup()}>×</a>

                <form id="sign_in_form">
                    <h1 id="sign_in_title">Sign in!</h1>
                    <input id="email_sign_in" placeholder="Email" className="input_field" />
                    <input id="password_sign_in" placeholder="password" className="input_field" />

                    <input type="reset" id="reset_form_sign_in" hidden />
                    <input id="sign_up_button" type="button" className="sign_in_button" onClick={process_sign_in} submit="false" value="Sign in" />
                    <p id="create_account" className="popup_bottom_text">Don't have an account? <a className="disable_link_highlight" onClick={switch_to_sign_up}>Register</a></p>
                </form>

                <form id="sign_up_form" hidden>
                    <h1 id="sign_in_title">Sign up!</h1>
                    <input id="email_sign_up" placeholder="Email" className="input_field" />
                    <input id="password_sign_up" placeholder="password" className="input_field" />
                    <input id="username_sign_up" placeholder="name (not required)" className="input_field" />

                    <input type="reset" id="reset_form_sign_up" hidden />
                    <input id="sign_up_button" type="button" className="sign_in_button" onClick={process_sign_up} submit="false" value="Sign up" />
                    <p id="sign_in_if_account_exists" className="popup_bottom_text">Already have an account? <a className="disable_link_highlight" onClick={switch_to_sign_in}>Sign in</a></p>
                </form>

                <form id="shut_down_form" hidden>
                    <h1 id="sign_in_title">Shut Down Site :(</h1>
                    <input id="email_shut_down" placeholder="Email" className="input_field" />
                    <input id="password_shut_down" placeholder="password" className="input_field" />
                    <input id="shut_down_button" type="button" className="sign_in_button" onClick={process_shut_down} submit="false" value="Kill that lil' shit" />
                </form>
            </div>
        </div>

        <label id="size" style={{ position: 'absolute', color: 'white', fontSize: '30px', bottom: 0 }}></label>
    </>
}