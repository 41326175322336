import axios from '../config/axiosConfig'
import { useEffect, useState } from "react";
import styled from "styled-components"
import ReactPlayer from 'react-player/lazy'


export default function Doom() {
    const source = "http://localhost:3001/stream/video_stream"
    const width = 500
    const [url, setUrl] = useState('');
    const [type, setType] = useState('');

    setTimeout(() => console.log(url), 1000);;
    return <div style={{ width: '100vw', height: '100vh' }}>
        <div style={{ width: '100%', textAlign: 'center', marginTop: '60px' }}>
            <select style={{ width: '268px' }} onChange={(e) => {
                console.log("A", e, e.target.value, "AAA", e.selectedIndex);
                setUrl(`${source}?ep=${e.target.value}`);
            }}>
                <option value="-1" >select the episode</option>
                <option value="pt1">DLC1 - 1</option>
                <option value="start-pt1">start - 1</option>
                <option value="start-pt2">start - 2</option>
                <option value="start-pt3">start - 3</option>
                <option value="start-pt4">start - 4</option>
                <option value="start-pt5">start - 5</option>
                <option value="start-pt6">start - 6</option>
                <option value="start-pt7">start - 7</option>
            </select>
        </div>
        <div style={{ textAlign: 'center', margin: 'auto', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
            {url &&
                <video controls width="500px" key={url} id={url}>
                    <source src={url} type='video/mp4' />
                </video>
            }
        </div>
    </div>
}
