import { useEffect } from "react"
import { download_file_when_body_loads } from '../js/downloader'

// isn't ported yet
export default function DownloaderLegacy() {
    useEffect(() => {
        download_file_when_body_loads();
    }, [])

    return <>

        <element onkeypress="send_request(event)"></element>
        <div id="logo_div" class="lumi_logo">
            <a href="/"><img src="../images/LUMI_white.ico" alt="logo" id="lumi_logo_img" class="lumi_logo_img" /></a>
        </div>

        <div style="text-align: center;">
            <h1>Youtube Video Downloader!</h1>
        </div>

        <button id="remove_cookies" onclick="remove_cookies('download_location')">Remove download cookies</button>
        <button id="click_me" onclick="click_me()">Click me!</button>

        <div id="download_links_container">
            <div id="download_links" class="side_panel">
                <a href="javascript:void(0)" class="close_button" onclick="close_side_panel('download_links')">×</a>
                <a href="javascript:void(0)" onclick="remove_download_urls()" style="position: absolute; top: -2.5px; right: 50px; font-size: 27px;">clear</a>

                <div id="incomplete_download_links" style="position: relative; top: -10px;">
                    <h3>not completed downloads</h3>
                </div>
                <div id="complete_download_links" style="position: relative; margin-top: 20px;">
                    <h3>completed downloads</h3>
                </div>
            </div>
            <button id="open_download_links_div" onclick="open_side_panel('download_links', 'auto')" class="open_sidepanel_button_custom">prev Download links</button>
        </div>

        <div id="main_content_wrapper">
            <div id="downloader_params_wrapper">
                <form id="downloader_params">

                    <select name="format" id="format_selector" onchange="on_select(this.selectedIndex)">
                        <option value="default" id="default_format_selector">Download format</option>
                        <option value="mp3" id="mp3">audio only</option>
                        <option value="mp4" id="mp4">audio + video</option>
                    </select>


                    <div id="mp4_selector" hidden>
                        <input type="entry" name="resolution" id="resolution" placeholder="Resolution (def=1080p)" />

                        <select name="extension" id="extension_selector">
                            <option value="mp4" id="default_extension_selector">Video Extension (def=mp4)</option>
                            <option value="mp4" id="mp4" name="mp4">mp4</option>
                            <option value="webm" id="webm" name="webm">webm</option>
                        </select>
                    </div>

                    <div id="a">
                        <input type="entry" name="url" id="url" placeholder="url of the playlist/video" />
                        <input type="button" id="enter" onclick="send_request(undefined)" value="enter" />
                    </div>
                </form>
            </div>

            <div id="size_paragraphs_wrapper">
            </div>



            <div id="explenation_wrapper">
                <p id="explanation" class="normal_paragraph"> Now, about that resolution.
                    the allowed values are: [144p, 240p, 360p, 480p, 720p, 1080p, 1440p, 2160p]
                    note that the downloader can not download resolution higher than the youtube video supports

                    This page is designed to download videos from youtube.
                    It is not really that well designed and could use a little bit of programming, but because im lazy
                    it just communicates back aand forth with a python script that i already wrote.
                    As such, it is only client sided and i hope to improve it in the future when I can actually be bothered to research javascript libraries
                </p>
            </div>
        </div>

        <div id="embed_parent">
            <p id="embed_parent_explanation_paragraph">
                click on enter and watch the video here :)
            </p>
            <div id="embed">
            </div>
        </div>
    </>
}