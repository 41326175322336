import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import { Component } from "react";
import MainPage from "./components";
import DownloaderLegacy from "./legacy/html/downloader";
import Downloader from "./pages/downloader";
import lumi_white_icon from "./images/LUMI_white.ico"
import Doom from "./pages/doom";

export default class App extends Component {

    state = {
        drawingPages: {
            downloader: <Downloader />,
            main: <MainPage />
        },
        drawPage: "main"
    }


    render() {
        return <BrowserRouter>
            <div id="logo_div" className="lumi_logo">
                <Link to="/">
                    <img src={lumi_white_icon} alt="" id="lumi_logo_img" className="lumi_logo_img" />
                </Link>
            </div>
            <Routes>
                <Route path="/" element={<MainPage />} />
                <Route index element={<MainPage />} />
                <Route path="downloader" element={<Downloader />} />
                <Route path="doom" element={<Doom />} />
                <Route path="downloader_legacy" element={<DownloaderLegacy />} />
                <Route path="downloader_legacy" element={<DownloaderLegacy />} />
                <Route path="downloader_legacy" element={<DownloaderLegacy />} />
                <Route path="downloader_legacy" element={<DownloaderLegacy />} />
            </Routes>
        </BrowserRouter>
        // <>
        //     {this.state.drawingPages[this.state.drawPage]}
        // </>;
    }
}

