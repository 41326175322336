import $ from 'jquery'
import { is_valid_email, get_cookie, send_sign_in_request, send_encrypted_message, encrypt_password_w_key } from './api'


export function enable_login_popup() {

    $("#login_popup").show();
    $("#main_body_container").addClass("blur");
    switch_to_sign_in();
}
export function disable_login_popup() {
    $("#login_popup").hide();
    $("#main_body_container").removeClass("blur");
}


export function switch_to_sign_up() {
    $("#sign_in_form").hide();
    $("#sign_up_form").show();
}


export function switch_to_sign_in() {
    $("#sign_in_form").show();
    $("#sign_up_form").hide();
}

export function logged_in(password, email) {

    $("#reset_form_sign_in").click();
    $("#login_popup").hide();
    $("#main_body_container").removeAttr('class')
    set_login_cookie(password, email);
    console.log(document.cookie)
    $("#account_profile_picture").attr("src", "../images/logged_in_image.png");
    $("#account_profile_picture").removeAttr('onclick');
    $("#account_profile_picture").on("click", function () {
        window.location.href = window.location.host + "/html/downloader.html";

    });
}

export function set_login_cookie(password, email) {
    let cookie = get_cookie("logged_in_user");

    // if (cookie != null){
    //     remove_cookie("logged_in_user");
    // }
    document.cookie = "logged_in_user=" + email + "," + password + "; SameSite=LAX;";
}

export function process_sign_in_response(response_value, send_feedback) {
    console.log(response_value);

    if (response_value == "correct_password") {
        //logged_in(password, email);
        if (send_feedback) { alert("you have been logged in") }
    } else if (response_value == "wrong_password") {
        if (send_feedback) { alert("the password or email is incorrect"); }
    } else {
        if (send_feedback) { alert("the user does not exist"); }
    }

}


export function process_shut_down() {


    let email = $("#email_shut_down").val();
    let password = $("#password_shut_down").val();

    email = email.replace(" ", "");

    if (!is_valid_email(email) || email == "") {
        alert("invalid email");
        return;
    }
    if (password == "" || password == null || password == undefined) {
        alert("invalid password");
        return;
    }

    send_sign_in_request(email, password, { additional_url_info: { main_user: true } });
}


export function process_sign_in() {

    let email = $("#email_sign_in").val();
    let password = $("#password_sign_in").val();

    email = email.replace(" ", "");

    if (!is_valid_email(email) || email == "") {
        alert("invalid email");
        return;
    }
    if (password == "" || password == null || password == undefined) {
        alert("invalid password");
        return;
    }

    send_sign_in_request(email, password);
}

export function process_sign_up(e) {
    //stop form submission

    $("#sign_up_button").prop("disabled",)

    let email = is_valid_email($("#email_sign_up").val());
    let registration_password = $("#password_sign_up").val();
    let username = $("#username_sign_up").val();

    email = email.replace(" ", "");

    if (email == null) {
        alert("invalid email");
        return;
    }

    if (registration_password == "" || registration_password == null || registration_password == undefined) {
        alert("invalid password");
        return;
    }

    let [password, key] = encrypt_password_w_key(registration_password, email);

    send_encrypted_message(
        "/redirect/add_user_main?",
        {
            email: email,
            password: password,
            username: username
        },
        key,
        function (data, textStatus, request) {
            let response_value = request.getResponseHeader("response_value");
            if (response_value == "user_exists") {
                alert("user with this email already exists");
            } else if (response_value == "user_created") {
                alert("the user has been created!")
            }
            $("#sign_up_button").prop("disabled", false)
        },
        {
            error_callback: function (JqueryXMLHttpRequest, textStatus, errorThrown) {
                alert("error sending data to server. \nerror: " + JqueryXMLHttpRequest.getResponseHeader("error"));
            }
        }
    );
}


export function on_body_load() {
    let logged_in_user = get_cookie("logged_in_user");

    if (logged_in_user != null) {
        logged_in_user = logged_in_user.split(",");
        //send_sign_in_request(logged_in_user[0], logged_in_user[1], {send_feedback:false});
    }
}

// as soon as the document loads we want to login the user
on_body_load();
// // var worker = new Worker('/scripts/worker.js');
// worker.onmessage = function (event) {
//     $("#size").text = event.data;
// }

